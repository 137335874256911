import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import stripe from './StripeInstance.js';
import api from './Api';
import { useUser } from './UserContext';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 670px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const ModalTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
`;

const ModalBody = styled.div`
  margin-bottom: 20px;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Button = styled.button`
  display: block;
  width: 40%;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  background-color: #53736A;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 10px auto;
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const CloseButton = styled(Button)`
  background-color: #6c757d;
  &:hover {
    background-color: #5a6268;
  }
`;

const PlanOptions = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const PlanOption = styled.div`
  flex: 1;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? '#e0f0e0' : '#fff')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  h3 {
    margin-top: 0;
  }

  p {
    margin-bottom: 10px;
    color: #3E7C59;
    font-weight: bold;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li {
    margin-bottom: 5px;
    color: #888;
    white-space: nowrap;
  }
`;

const CurrentPlanIndicator = styled.span`
  background-color: #4CAF50;
  color: white;
  padding: 2px 6px;
  border-radius: 3px;
  font-size: 12px;
  margin-left: 5px;
`;

const ErrorMessage = styled.p`
  color: red;
  margin-bottom: 10px;
`;

const PlanModal = ({ isOpen, onClose, onSubmit, currentPlan }) => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { user, fetchUserData } = useUser();

  useEffect(() => {
    if (currentPlan) {
      setSelectedPlan(currentPlan);
    }
  }, [currentPlan]);

  const handleSubscribe = async (plan) => {
    const metadata = {
      plan,
      customer_email: user.email,
    };

    try {
      const token = localStorage.getItem('token');
      const response = await api.post('/create-checkout-session', {
        plan,
        metadata,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const sessionId = response.data.sessionId;
      await stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      console.error('Error creating Checkout session:', error);
      throw error;
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setErrorMessage('');
  
    try {
      if (selectedPlan === user.plan) {
        onClose();
        return;
      }
  
      const token = localStorage.getItem('token');
  
      if (selectedPlan === 'free' && user.plan !== 'free') {
        // Handle cancellation
        await api.post('/cancel-subscription', {}, {
          headers: { Authorization: `Bearer ${token}` },
        });
        await fetchUserData();
        onSubmit('free');
      } else if (selectedPlan !== 'free' && user.plan === 'free') {
        // Handle upgrade from free to paid plan
        await handleSubscribe(selectedPlan);
      } else if (selectedPlan !== 'free' && user.plan !== 'free') {
        // Handle changes between paid plans
        if ((user.plan === 'premium' && selectedPlan === 'pro') || 
            (user.plan === 'pro' && selectedPlan === 'premium')) {
          await api.patch('/user', { plan: selectedPlan }, {
            headers: { Authorization: `Bearer ${token}` },
          });
          await fetchUserData();
          onSubmit(selectedPlan);
        } else {
          // This case shouldn't happen, but handle it just in case
          setErrorMessage('Invalid plan change. Please try again.');
        }
      }
    } catch (error) {
      console.error('Error during submission:', error);
      setErrorMessage('An unexpected error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  const planDetails = [
    { name: 'free', title: 'Free', price: '$0/month', features: ['1 city', '200 queries per month', 'Queries reset monthly'] },
    { name: 'premium', title: 'Premium', price: '$20/month', features: ['All cities & states', '2000 queries per month', 'Queries reset monthly'] },
    { name: 'pro', title: 'Pro', price: '$50/month', features: ['All cities & states', '10,000 queries per month', 'Queries reset monthly'] },
  ];

  return (
    <ModalOverlay>
      <ModalContent>
        <ModalTitle>Choose your plan</ModalTitle>
        <ModalBody>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <FormGroup>
            <PlanOptions>
              {planDetails.map((plan) => (
                <PlanOption
                  key={plan.name}
                  selected={selectedPlan === plan.name}
                  onClick={() => setSelectedPlan(plan.name)}
                >
                  <h3>
                    {plan.title}{' '}
                    {currentPlan === plan.name && <CurrentPlanIndicator>Current</CurrentPlanIndicator>}
                  </h3>
                  <p>{plan.price}</p>
                  <ul>
                    {plan.features.map((feature, index) => (
                      <li key={index}>{feature}</li>
                    ))}
                  </ul>
                </PlanOption>
              ))}
            </PlanOptions>
          </FormGroup>
        </ModalBody>
        <Button onClick={handleSubmit} disabled={isSubmitting || selectedPlan === user.plan}>
          {isSubmitting ? 'Processing...' : selectedPlan === user.plan ? 'Current Plan' : 'Select Plan'}
        </Button>
        <CloseButton onClick={onClose}>
          Close
        </CloseButton>
      </ModalContent>
    </ModalOverlay>
  );
};

export default PlanModal;
