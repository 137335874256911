import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import api from './Api';
import { useUser } from './UserContext';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 650px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const ModalTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
`;

const ModalBody = styled.div`
  margin-bottom: 20px;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const SubmitButton = styled.button`
  display: block;
  width: 40%;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  background-color: #53736A;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: auto;
`;

const ErrorMessage = styled.p`
  color: red;
  margin-bottom: 10px;
`;

const LocationModal = ({ isOpen, onClose, onSubmit }) => {
  const [selectedLocation, setSelectedLocation] = useState('');
  // const [locationType, setLocationType] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { user, cities } = useUser();

  const uniqueStates = useMemo(() => {
    const stateMap = new Map();
    cities.forEach(city => {
      if (!stateMap.has(city.state.id)) {
        stateMap.set(city.state.id, city.state);
      }
    });
    return Array.from(stateMap.values());
  }, [cities]);

  useEffect(() => {
    if (user && user.location_choice) {
      //setLocationType(user.location_choice);
      setSelectedLocation(user.location_choice === 'city' ? `city-${user.city?.id}` : `state-${user.state?.id}`);
    }
  }, [user]);

  const handleSubmit = async () => {
    if (!selectedLocation) {
      setErrorMessage('Please select a location.');
      return;
    }

    setIsSubmitting(true);
    setErrorMessage('');

    try {
      const token = localStorage.getItem('token');
      const [type, id] = selectedLocation.split('-');
      const data = {
        location_choice: type,
        needs_onboarding: false
      };

      if (type === 'city') {
        data.city_id = parseInt(id);
      } else {
        data.state_id = parseInt(id);
      }
      
      const response = await api.patch('/user', data, {
        headers: { Authorization: `Bearer ${token}` },
      });

      onSubmit(response.data);
      onClose();
    } catch (error) {
      console.error('Error during submission:', error);
      setErrorMessage('An unexpected error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen || !user || !cities || cities.length === 0) return null;

  const isPaidPlan = user.plan === 'premium' || user.plan === 'pro';
  let locationOptions = [];

  if (isPaidPlan) {
    locationOptions = cities.map(city => ({ ...city, type: 'city', value: `city-${city.id}` }));
  } else {
    locationOptions = [
      ...cities.map(city => ({ ...city, type: 'city', value: `city-${city.id}` })),
      ...uniqueStates.map(state => ({ ...state, type: 'state', value: `state-${state.id}` }))
    ];
  }

  // Sort options alphabetically
  locationOptions.sort((a, b) => {
    if (a.type === b.type) {
      return a.name.localeCompare(b.name);
    }
    return a.type.localeCompare(b.type);
  });

  return (
    <ModalOverlay>
      <ModalContent>
        <ModalTitle>Choose your location</ModalTitle>
        <ModalBody>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <FormGroup>
            <Label htmlFor="location">Location:</Label>
            <Select
              id="location"
              value={selectedLocation}
              onChange={(e) => {
                setSelectedLocation(e.target.value);
                // setLocationType(e.target.value.split('-')[0]);
              }}
              disabled={isSubmitting}
            >
              <option value="">Select a location</option>
              {locationOptions.map((option) => (
                <option 
                  key={option.value}
                  value={option.value}
                >
                  {option.type === 'state' 
                    ? `${option.name} Legislature` 
                    : `${option.name}, ${option.state.abbreviation}`}
                </option>
              ))}
            </Select>
          </FormGroup>
        </ModalBody>
        <SubmitButton onClick={handleSubmit} disabled={isSubmitting}>
          {isSubmitting ? 'Updating...' : 'Select Location'}
        </SubmitButton>
      </ModalContent>
    </ModalOverlay>
  );
};

export default LocationModal;