import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
// import moment from 'moment';
import TopSection from './TopSection';
import api from './Api';
import { useUser } from './UserContext';
import PlanModal from './PlanModal'; 
import LocationModal from './LocationModal';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #E2E3DE;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  overflow-y: auto;
  box-sizing: border-box;
  padding-top: 40px;
  padding-bottom: 40px;
`;

const TabContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 600px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
`;

const Tab = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  color: #888;
  border-bottom: none;

  &.active {
    color: #333;
    border-bottom: 2px solid #333;
    font-weight: bold;
  }
`;

const SettingsSection = styled.div`
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 600px;
`;

const SettingsTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
  color: #333333;
`;

const NameInput = styled.input`
  padding: 10px;
  font-size: 16px;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  margin-right: 10px;
`;

const UpdateButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  background-color: #3B4C55;
  color: #FFFFFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #4C6573;
  }
`;

const DeleteButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  background-color: #FF5555;
  color: #FFFFFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #FF7777;
  }
`;

const ConfirmationOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

const ConfirmationModal = styled.div`
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 20px;
  max-width: 400px;
  text-align: center;
`;

const ConfirmationTitle = styled.h3`
  font-size: 20px;
  margin-bottom: 10px;
`;

const ConfirmationButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  margin: 0 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  background-color: #3B4C55;
  color: #FFFFFF;

  &:hover {
    background-color: #4C6573;
  }

  &.danger {
    background-color: #FF5555;
    color: #FFFFFF;

    &:hover {
      background-color: #FF7777;
    }
  }
`;


const PlanInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const PlanRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const PlanInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const PlanName = styled.span`
  font-weight: bold;
`;

const PlanDetails = styled.span`
  font-size: 0.9em;
  color: #666;
`;

const EditButton = styled.button`
  padding: 8px 16px;
  font-size: 14px;
  background-color: #3B4C55;
  color: #FFFFFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  width: 180px;
  height: 60px; // Increased height to accommodate two lines
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.2;
  white-space: normal; // Allow text to wrap

  &:hover {
    background-color: #4C6573;
  }
`;

const UploadSection = styled.div`
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const UploadTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
  color: #333333;
`;

const UploadArea = styled.div`
  border: 2px dashed #CCCCCC;
  border-radius: 10px;
  padding: 40px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #F5F5F5;
  }

  &.dragging {
    background-color: #F5F5F5;
  }
`;

const UploadIcon = styled.i`
  font-size: 48px;
  color: #888888;
  margin-bottom: 10px;
`;

const UploadText = styled.p`
  font-size: 18px;
  color: #888888;
`;

const UploadInput = styled.input`
  display: none;
`;


const UploadedFileList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-top: 20px;
  width: 100%;
`;

const UploadedFileItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #CCCCCC;
  width: 100%;
`;

const UploadedFileName = styled.span`
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 20px;
`;

const DeleteFileButton = styled.button`
  padding: 5px 10px;
  font-size: 14px;
  background-color: #FF5555;
  color: #FFFFFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  margin-left: auto;

  &:hover {
    background-color: #FF7777;
  }
`;

const Settings = () => {
  const [activeTab, setActiveTab] = useState('profile');
  const [name, setName] = useState('');
  // const [userPlan, setUserPlan] = useState('');
  const [userCity, setUserCity] = useState('');
  const [showPlanModal, setShowPlanModal] = useState(false);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [queriesLeft, setQueriesLeft] = useState(null);
  // const [planExpirationDate, setPlanExpirationDate] = useState(null);
  const [featureFlags, setFeatureFlags] = useState({});
  const [locationUpdated, setLocationUpdated] = useState(false);
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);

  const { user, fetchUserData } = useUser();

  const fetchUploadedFiles = useCallback(async () => {
    if (!featureFlags.can_upload_files) return;

    try {
      const token = localStorage.getItem('token');
      const response = await api.get('/uploaded-files', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUploadedFiles(response.data.files);
    } catch (error) {
      console.error('Error fetching uploaded files:', error);
    }
  }, [featureFlags]);

  const fetchSubscriptionInfo = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await api.get('/subscription-info', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSubscriptionInfo(response.data);
    } catch (error) {
      console.error('Error fetching subscription info:', error);
    }
  }, []);

  const fetchQueriesLeft = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await api.get('/user', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setQueriesLeft(response.data.queries_left);
    } catch (error) {
      console.error('Error fetching queries left:', error);
    }
  }, []);

  const handleDeleteFile = async (filename) => {
    try {
      const token = localStorage.getItem('token');
      await api.delete(`/uploaded-files/${filename}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUploadedFiles(uploadedFiles.filter((file) => file !== filename));
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  const handleNameUpdate = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await api.patch(
        '/user',
        { name },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const updatedUser = response.data;
      setName(updatedUser.name);
      localStorage.setItem('user', JSON.stringify(updatedUser));
      fetchUserData(); // Refresh user data in context
      alert('Name updated successfully');
    } catch (error) {
      console.error('Error updating name:', error);
    }
  };

  const handlePlanUpdate = async (selectedPlan) => {
    setShowPlanModal(false);
    try {
      await fetchUserData();
      await fetchSubscriptionInfo();
      if (selectedPlan === 'cancel') {
        alert('Subscription cancelled successfully. Your plan will revert to Free at the end of the billing period.');
      } else if (selectedPlan !== subscriptionInfo?.plan) {
        alert('Plan updated successfully');
      }
      // Refresh feature flags
      setFeatureFlags(user.feature_flags || {});
    } catch (error) {
      console.error('Error updating plan:', error);
      alert('An error occurred while updating the plan. Please try again.');
    }
  };

  const handleLocationUpdate = async () => {
    setShowLocationModal(false);
    setLocationUpdated(true);
  };

  useEffect(() => {
    if (locationUpdated) {
      const updateUserData = async () => {
        await fetchUserData();
        setLocationUpdated(false);
      };
      updateUserData();
    }
  }, [locationUpdated, fetchUserData]);

  useEffect(() => {
    if (user) {
      setName(user.name);
      setUserCity(user.city && user.state ? `${user.city.name}, ${user.state.name}` : '');
      setFeatureFlags(user.feature_flags || {});
      if (user.feature_flags && user.feature_flags.can_upload_files) {
        fetchUploadedFiles();
      }
      fetchSubscriptionInfo();
      fetchQueriesLeft(); 
    }
  }, [user, fetchUploadedFiles, fetchSubscriptionInfo, fetchQueriesLeft]);

  const handleDeleteConfirmation = () => {
    setShowDeleteModal(true);
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteAccount = async () => {
    try {
      const token = localStorage.getItem('token');
      await api.delete('/user', {
        headers: { Authorization: `Bearer ${token}` },
      });
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      fetchUserData();
      window.location.href = '/';
    } catch (error) {
      console.error('Error deleting account:', error);
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    const filename = file.name;
    const allowedExtensions = ['.txt', '.md', '.html', '.doc', '.docx', '.csv', '.xls', '.xlsx', '.pdf'];
    const fileExtension = filename.slice(filename.lastIndexOf('.')).toLowerCase();
    const maxFileSizeInBytes = 5 * 1024 * 1024; // 5MB
  
    if (!allowedExtensions.includes(fileExtension)) {
      alert('Invalid file type. Only .txt, .md, .html, .doc, .docx, .csv, .xls, .xlsx, and .pdf files are allowed.');
      return;
    }
  
    if (file.size > maxFileSizeInBytes) {
      alert('File size exceeds the maximum limit of 5MB.');
      return;
    }
  
    const formData = new FormData();
    formData.append('file', file);
  
    try {
      const token = localStorage.getItem('token');
      await api.put(`/upload/${filename}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
      alert('File uploaded successfully');
      fetchUploadedFiles();
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    setIsDragging(false);

    const file = event.dataTransfer.files[0];
    const filename = file.name;

    const formData = new FormData();
    formData.append('file', file);

    try {
      const token = localStorage.getItem('token');
      await api.put(`/upload/${filename}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
      alert('File uploaded successfully');
      fetchUploadedFiles();
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const renderPlanInfo = () => {
    if (!subscriptionInfo) return null;
  
    const { plan, status, cancel_at_period_end, current_period_end, is_trial, trial_end } = subscriptionInfo;
    const planName = plan.charAt(0).toUpperCase() + plan.slice(1);
    
    return (
      <PlanInfoContainer>
        <PlanRow>
          <PlanInfo>
            <PlanName>Current Plan: {planName}</PlanName>
            <PlanDetails>
              {queriesLeft !== null && `${queriesLeft} queries left`}
            </PlanDetails>
            <PlanDetails>
              {is_trial && (
                <>
                  Trial period ends on {new Date(trial_end * 1000).toLocaleDateString()}
                  <br />
                </>
              )}
              {status === 'active' && !cancel_at_period_end && plan !== 'free' && current_period_end && (
                `Plan ${is_trial ? 'will start' : 'renews'} on ${new Date(current_period_end * 1000).toLocaleDateString()}`
              )}
              {cancel_at_period_end && plan !== 'free' && current_period_end && (
                `Plan ends on ${new Date(current_period_end * 1000).toLocaleDateString()}`
              )}
              {plan === 'free' && 'Free plan'}
            </PlanDetails>
            {cancel_at_period_end && plan !== 'free' && (
              <PlanDetails style={{ color: '#FF5555' }}>
                Your subscription has been canceled and will not renew
              </PlanDetails>
            )}
            {is_trial && (
              <PlanDetails style={{ color: '#4CAF50' }}>
                You're currently on a trial period
              </PlanDetails>
            )}
          </PlanInfo>
          <EditButton onClick={() => setShowPlanModal(true)}>
            Manage Subscription
          </EditButton>
        </PlanRow>
      </PlanInfoContainer>
    );
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <Wrapper>
      <TopSection authenticated={true} />
      <Container>
        <TabContainer>
          <Tab
            className={activeTab === 'profile' ? 'active' : ''}
            onClick={() => setActiveTab('profile')}
          >
            Profile
          </Tab>
          <Tab
            className={activeTab === 'plan' ? 'active' : ''}
            onClick={() => setActiveTab('plan')}
          >
            Plan
          </Tab>
          {featureFlags.can_upload_files && (
            <Tab
              className={activeTab === 'upload' ? 'active' : ''}
              onClick={() => setActiveTab('upload')}
            >
              Upload
            </Tab>
          )}
        </TabContainer>
  
        {activeTab === 'profile' && (
          <SettingsSection>
            <SettingsTitle>Profile</SettingsTitle>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
              <NameInput
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <UpdateButton onClick={handleNameUpdate}>Update</UpdateButton>
            </div>
            <DeleteButton onClick={handleDeleteConfirmation}>Delete Account</DeleteButton>
          </SettingsSection>
        )}
  
        {activeTab === 'plan' && (
          <SettingsSection>
            <SettingsTitle>Plan</SettingsTitle>
            {renderPlanInfo()}
            <PlanRow>
              <PlanInfo>
                <PlanName>Current Location:</PlanName>
                <PlanDetails>{userCity || 'Not set'}</PlanDetails>
              </PlanInfo>
              {featureFlags.can_change_city && (
                <EditButton onClick={() => setShowLocationModal(true)}>
                  Manage Location
                </EditButton>
              )}
            </PlanRow>
          </SettingsSection>
        )}
  
        {activeTab === 'upload' && featureFlags.can_upload_files && (
          <UploadSection>
            <UploadTitle>Upload Documents</UploadTitle>
            <UploadArea
              className={isDragging ? 'dragging' : ''}
              onClick={() => document.querySelector('#fileInput').click()}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <UploadIcon className="fas fa-cloud-upload-alt" />
              <UploadText>
                Click or drag and drop files here
                <br /><br />
                Supported file types: .txt, .md, .html, .doc, .docx, .csv, .xls, .xlsx, .pdf
                <br />File size limit: 5MB
              </UploadText>
              <UploadInput id="fileInput" type="file" onChange={handleFileUpload} />
            </UploadArea>
            <UploadedFileList>
              {uploadedFiles.map((file) => (
                <UploadedFileItem key={file}>
                  <UploadedFileName>{file}</UploadedFileName>
                  {uploadedFiles.length > 0 && (
                    <DeleteFileButton onClick={() => handleDeleteFile(file)}>
                      Delete
                    </DeleteFileButton>
                  )}
                </UploadedFileItem>
              ))}
            </UploadedFileList>
          </UploadSection>
        )}
      </Container>

      <PlanModal
        isOpen={showPlanModal}
        onClose={() => setShowPlanModal(false)}
        onSubmit={handlePlanUpdate}
        currentPlan={subscriptionInfo ? subscriptionInfo.plan : user.plan}
      />
      
      <LocationModal
        isOpen={showLocationModal}
        onClose={() => setShowLocationModal(false)}
        onSubmit={handleLocationUpdate}
        currentLocation={userCity}
      />
  
      {showDeleteModal && (
        <ConfirmationOverlay>
          <ConfirmationModal>
            <ConfirmationTitle>Are you sure you want to delete your account?</ConfirmationTitle>
            <p>This action cannot be undone.</p>
            <div style={{ marginTop: '20px' }}>
              <ConfirmationButton className="danger" onClick={handleDeleteAccount}>
                Delete
              </ConfirmationButton>
              <ConfirmationButton onClick={handleDeleteCancel}>
                Cancel
              </ConfirmationButton>
            </div>
          </ConfirmationModal>
        </ConfirmationOverlay>
      )}
    </Wrapper>
  );
};

export default Settings;