import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import TopSection from './TopSection';
import googleLogo from '../icons/google.svg';
import loadingGIF from '../icons/loading.gif';
import '../fonts.css';
import PlanModal from './PlanModal';
import LocationModal from './LocationModal';
import api from './Api';
import { useUser } from './UserContext';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #E2E3DE;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 5vh 0; 
  height: calc(100vh - 100px); // Adjust this value based on your layout

  @media (min-height: 800px) {
    padding-top: 10vh;  
  }
`;

const ChatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 5px;
`;

const SearchBarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #E2E3DE;
  box-sizing: border-box;
`;

const SearchBar = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 600px;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-bottom: 30px;
`;

const SearchInput = styled.input`
  flex: 1;
  padding: 15px 20px;
  border: none;
  font-size: 16px;
  outline: none;
`;

const SearchButton = styled.button`
  padding: 15px 25px;
  background-color: #3B4C55;
  color: #fff;
  border: none;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #4C6573;
  }
`;

const ChatHistory = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
`;

const MessageCard = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.$isUserMessage ? 'row-reverse' : 'row')};
  align-items: flex-end;
  margin-bottom: 10px;
`;

const MessageBubble = styled.div`
  background-color: ${(props) => (props.$isUserMessage ? '#516254' : '#F2F2F2')};
  color: ${(props) => (props.$isUserMessage ? '#FFFFFF' : '#000000')};
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 70%;
  word-wrap: break-word;
`;

const MessageText = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 1.4;
  white-space: pre-wrap;
`;

const References = styled.div`
  margin-top: 10px;
  font-size: 14px;
`;

const ReferenceLink = styled.a`
  color: #007bff;
  text-decoration: none;
  margin-right: 5px;

  &:hover {
    text-decoration: underline;
  }
`;

const SuggestedQuestions = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const SuggestedQuestion = styled.button`
  padding: 12px;
  border: none;
  border-radius: 5px;
  background-color: #f0f0f0;
  cursor: pointer;
  text-align: left;
  margin-bottom: 15px;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const LoginSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 50px;
  background-color: #E2E3DE;
  text-align: center;
`;

const LoginTitle = styled.h2`
  font-size: 30px;
  font-family: 'custom-family-font', sans-serif;
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EmailInput = styled.input`
  padding: 10px;
  margin-bottom: 10px;
  width: 300px;
`;

const PasswordInput = styled.input`
  padding: 10px;
  margin-bottom: 10px;
  width: 300px;
`;

const LoginButton = styled.button`
  padding: 10px 20px;
  background-color: #03738C;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
`;

const GoogleLoginButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: #FFFFFF;
  color: #000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
`;

const GoogleLogo = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

const GoogleButtonText = styled.span`
  font-size: 16px;
`;

const OrDivider = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;
  color: #888;
  font-size: 14px;
  width: 100%;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #aaa;
  }

  &::before {
    margin-right: 10px;
  }

  &::after {
    margin-left: 10px;
  }
`;

const LoadingOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const LoadingIcon = styled.img`
  width: 50px;
  height: 50px;
`;

const SignUpLink = styled.div`
  margin-top: 20px;
  font-size: 14px;
  text-align: center;
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
`;

const ModalButton = styled.button`
  margin: 10px;
  padding: 10px 20px;
  background-color: #3B4C55;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #4C6573;
  }
`;

const WelcomeMessage = styled.div`
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5em;
  line-height: 1.4;
`;

const Home = () => {
  const [currentQuery, setCurrentQuery] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showQueryLimitModal, setShowQueryLimitModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const chatWrapperRef = useRef(null);
  const navigate = useNavigate();
  const [showPlanModal, setShowPlanModal] = useState(false);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const { user, login, isLoading: contextLoading, fetchUserData, cities } = useUser();
  const [suggestedQuestions, setSuggestedQuestions] = useState([]);

  const fetchSuggestedQuestions = useCallback(async () => {
    if (user && (user.city || user.state)) {
      try {
        const token = localStorage.getItem('token');
        const response = await api.get('/suggested-questions', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setSuggestedQuestions(response.data.suggested_questions);
      } catch (error) {
        console.error('Error fetching suggested questions:', error);
        setErrorMessage('Failed to fetch suggested questions. Please try again.');
      }
    }
  }, [user]);  
  
  const handleQuerySubmit = async (question = null) => {
    const query = question || currentQuery;
  
    if (query.trim() === '') {
      return;
    }
  
    setIsLoading(true);
    setErrorMessage('');
  
    try {
      const token = localStorage.getItem('token');
      const response = await api.post('/query-db', {
        query: query,
        chat_history: chatHistory.map((message) => message.content),
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      const assistantMessage = {
        content: response.data.answer,
        role: 'assistant',
        references: response.data.references,
      };
  
      const newMessage = {
        content: query,
        role: 'user',
      };
  
      setChatHistory([...chatHistory, newMessage, assistantMessage]);
      setCurrentQuery('');

      // Call scrollToBottom after updating the chat history
      setTimeout(scrollToBottom, 100);
    } catch (error) {
      if (error.response && error.response.status === 429) {
        setShowQueryLimitModal(true);
      } else if (error.response && error.response.status === 400 && error.response.data.needs_onboarding) {
        setShowPlanModal(true);
      } else {
        console.error('Error submitting query:', error);
        setErrorMessage(error.response?.data?.message || 'An error occurred. Please try again later.');
      }
    } finally {
      setIsLoading(false);
    }

    await fetchUserData();
  };

  const formatMessageText = (text) => {
    return text.split('\n').map((line, index, array) => (
      <React.Fragment key={index}>
        {line}
        {index < array.length - 1 && <br />}
      </React.Fragment>
    ));
  };

  const containerRef = useRef(null);

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const response = await api.get('/auth/initiate');
      window.location.href = response.data.auth_url;
    } catch (error) {
      console.error('Error initiating OAuth2 login:', error);
      setErrorMessage('Failed to initiate Google login. Please try again.');
    }
  };

  const handleGoogleLoginRedirect = useCallback(async () => {
    const params = new URLSearchParams(window.location.search);
    const userString = params.get('user');
    const token = params.get('token');
    const refreshToken = params.get('refresh_token');
    if (userString && token && refreshToken) {
      localStorage.setItem('user', userString);
      localStorage.setItem('token', token);
      localStorage.setItem('refreshToken', refreshToken);
      await fetchUserData();
      window.history.replaceState({}, document.title, '/');
    }
  }, [fetchUserData]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setIsLoading(true);

    const result = await login(email, password);

    if (result.success) {
      if (result.user.needs_onboarding) {
        setShowPlanModal(true);
      }
    } else {
      setErrorMessage(result.error);
    }

    setIsLoading(false);
  };

  const handleInputChange = (e) => {
    setCurrentQuery(e.target.value);
    setErrorMessage('');
  };

  const handlePlanSubmit = async (plan) => {
    try {
      const token = localStorage.getItem('token');
      const response = await api.patch('/user', { plan }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      await fetchUserData();
      setShowPlanModal(false);
      if (response.data.show_location_modal) {
        setShowLocationModal(true);
      }
    } catch (error) {
      console.error('Error updating plan:', error);
      setErrorMessage('Failed to update plan. Please try again.');
    }
  };

  const handleLocationSubmit = async (updatedUserData) => {
    try {
      await fetchUserData();
      if (updatedUserData.suggested_questions) {
        setSuggestedQuestions(updatedUserData.suggested_questions);
      } else {
        await fetchSuggestedQuestions();
      }
      setShowLocationModal(false);
    } catch (error) {
      console.error('Error updating user data:', error);
      setErrorMessage('Failed to update user data. Please try again.');
    }
  };

  const getWelcomeMessage = () => {
    if (!user) return ['', ''];
  
    let location = '';
    if (user.plan === 'free') {
      if (user.location_choice === 'city' && user.city) {
        location = user.city.name;
      } else if (user.location_choice === 'state' && user.state) {
        location = `${user.state.name} Legislature`;
      }
    } else if (user.plan === 'premium' || user.plan === 'pro') {
      if (user.city && user.state) {
        location = `${user.city.name} and ${user.state.name} Legislature`;
      } else if (user.state) {
        location = `${user.state.name} Legislature`;
      } else if (user.city) {
        location = user.city.name;
      }
    }
  
    const firstLine = location ? `Welcome to your ${location} personal analyst.` : 'Welcome to your personal analyst.';
    const secondLine = 'To get started, pick a sample or type your question.';
  
    return [firstLine, secondLine];
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  useEffect(() => {
    if (user) {
      if (user.needs_onboarding) {
        if (user.plan === null) {
          setShowPlanModal(true);
          setShowLocationModal(false);
        } else {
          setShowPlanModal(false);
          setShowLocationModal(true);
        }
      } else {
        setShowPlanModal(false);
        setShowLocationModal(false);
        fetchSuggestedQuestions();
      }
    } else {
      handleGoogleLoginRedirect();
    }
  }, [user, handleGoogleLoginRedirect, fetchSuggestedQuestions]);

  if (contextLoading) {
    return (
      <LoadingOverlay>
        <LoadingIcon src={loadingGIF} alt="Loading" />
      </LoadingOverlay>
    );
  }

  if (!user) {
    return (
      <div>
        <Wrapper>
          <TopSection authenticated={false} userName="" />
          <Container>
            <LoginSection>
              <LoginTitle>Learn your local and state government's voting history</LoginTitle>
              <LoginForm onSubmit={handleLogin}>
                <EmailInput
                  type="email"
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <PasswordInput
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginBottom: '10px' }}>
                  <a href="/forgot-password" style={{ fontSize: '14px', color: '#007bff', textDecoration: 'none' }}>
                    Forgot password?
                  </a>
                </div>
                <LoginButton type="submit">Continue with email</LoginButton>
                <SignUpLink>
                  Don't have an account?{' '}
                  <a href="/register" style={{ color: '#007bff', textDecoration: 'none', fontWeight: 'bold' }}>
                    Sign up
                  </a>
                </SignUpLink>
                <OrDivider>or</OrDivider>
                <GoogleLoginButton type="button" onClick={handleGoogleLogin}>
                  <GoogleLogo src={googleLogo} alt="Google Logo" />
                  <GoogleButtonText>Continue with Google</GoogleButtonText>
                </GoogleLoginButton>
              </LoginForm>
              {errorMessage && <div style={{ color: 'red', marginTop: '10px' }}>{errorMessage}</div>}
            </LoginSection>
          </Container>
        </Wrapper>
      </div>
    );
  }
  
  return (
    <div>
      <Wrapper>
        <TopSection authenticated={!!user} userName={user ? user.name : ""} />
        <Container ref={containerRef}>
          <ChatWrapper ref={chatWrapperRef}>
            {chatHistory.length > 0 && (
              <ChatHistory>
                {chatHistory.map((message, index) => (
                  <React.Fragment key={index}>
                    {message.role === 'user' && (
                      <MessageCard $isUserMessage={true}>
                        <MessageBubble $isUserMessage={true}>
                          <MessageText>{formatMessageText(message.content)}</MessageText>
                        </MessageBubble>
                      </MessageCard>
                    )}
                    {message.role === 'assistant' && (
                      <MessageCard $isUserMessage={false}>
                        <MessageBubble $isUserMessage={false}>
                          <MessageText>{formatMessageText(message.content)}</MessageText>
                          {message.references && message.references.length > 0 && (
                            <References>
                              References:
                              {message.references.map((ref, refIndex) => (
                                <ReferenceLink 
                                  key={refIndex} 
                                  href={ref.url} 
                                  target="_blank" 
                                  rel="noopener noreferrer"
                                >
                                  {refIndex + 1}
                                </ReferenceLink>
                              ))}
                            </References>
                          )}
                        </MessageBubble>
                      </MessageCard>
                    )}
                  </React.Fragment>
                ))}
              </ChatHistory>
            )}
  
            {chatHistory.length === 0 && user && (user.city || user.state) && (
              <>
              <WelcomeMessage>
                {getWelcomeMessage().map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    {index === 0 && <br />}
                  </React.Fragment>
                ))}
              </WelcomeMessage>
              <SearchBarWrapper>
                <SearchBar>
                  <SearchInput
                    type="text"
                    placeholder="Ask a question..."
                    value={currentQuery}
                    onChange={handleInputChange}
                    onKeyDown={(e) => e.key === 'Enter' && handleQuerySubmit()}
                  />
                  <SearchButton onClick={() => handleQuerySubmit()}>Submit</SearchButton>
                </SearchBar>
              </SearchBarWrapper>
              <SuggestedQuestions>
                {suggestedQuestions.map((question, index) => (
                  <SuggestedQuestion
                    key={index}
                    onClick={() => handleQuerySubmit(question)}
                  >
                    {question}
                  </SuggestedQuestion>
                ))}
              </SuggestedQuestions>
              </>
            )}
          </ChatWrapper>
        </Container>
        {chatHistory.length > 0 && user && user.city && (
          <SearchBarWrapper>
            <SearchBar>
              <SearchInput
                type="text"
                placeholder="Ask a question..."
                value={currentQuery}
                onChange={handleInputChange}
                onKeyDown={(e) => e.key === 'Enter' && handleQuerySubmit()}
              />
              <SearchButton onClick={() => handleQuerySubmit()}>Submit</SearchButton>
            </SearchBar>
          </SearchBarWrapper>
        )}
        {errorMessage && <div style={{ color: 'red', marginTop: '10px', textAlign: 'center' }}>{errorMessage}</div>}
      </Wrapper>

      {isLoading && (
        <LoadingOverlay>
          <LoadingIcon src={loadingGIF} alt="Loading" />
        </LoadingOverlay>
      )}

      <PlanModal
        isOpen={showPlanModal}
        onClose={() => setShowPlanModal(false)}
        onSubmit={handlePlanSubmit}
        user={user}
      />

      <LocationModal
        isOpen={showLocationModal}
        onClose={() => setShowLocationModal(false)}
        onSubmit={handleLocationSubmit}
        cities={cities}
      />

      {showQueryLimitModal && (
        <Modal>
          <ModalContent>
            <h3>Query Limit Reached</h3>
            <p>You've reached your monthly query limit. Please upgrade your plan in the settings page to continue.</p>
            <ModalButton onClick={() => setShowQueryLimitModal(false)}>Close</ModalButton>
            <ModalButton onClick={() => {
              setShowQueryLimitModal(false);
              navigate('/settings');
            }}>Go to Settings</ModalButton>
          </ModalContent>
        </Modal>
      )}
    </div>
  );
};

export default Home;